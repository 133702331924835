<template>
  <div>
    <v-card>
      <v-card-title> Customer Profile </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout row-wrap>
          <v-flex xs12>
            <v-autocomplete
              :items="customers"
              v-model="CustomerId"
              :rules="Rules"
              color="white"
              item-text="customerName"
              label="اسم العميل"
              item-value="customerNumber"
              required
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete
              :items="products"
              v-model="ProductId"
              color="white"
              item-text="productNumber"
              label="اسم المنتج"
              :rules="Rules"
              item-value="productNumber"
              required
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <template>
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="date2"
                width="290px"
                attach=""
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="تاريخ البداية"
                    prepend-icon="mdi-calendar"
                    format="MM/DD/yyyy"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  format="MM/DD/yyyy"
                  v-model="startDate"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    إغلاق
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog2.save(date2)"
                  >
                    حفظ
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </template>
          </v-flex>
          <v-flex xs12>
            <template>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                 attach=""
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="تاريخ النهاية"
                    prepend-icon="mdi-calendar"
                    :rules="EndDateRules"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker format="MM/DD/yyyy" v-model="endDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    إغلاق
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    حفظ
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </template>
          </v-flex>
        </v-layout>
        <v-layout row-wrap>
          <v-spacer></v-spacer>
          <v-flex xs12>
            <v-btn
              @click="getByProductandCustomer()"
              style="
                background: #5867dd;
                color: white;
                margin-left: 5px;
                width: 100px;
              "
              dark
            >
              <b>بحث</b>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <apexchart :series="series" :options="options"></apexchart>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="tables">
      <v-col xl="6" md="6">
        <v-card>
          <v-card-title
            class="justify-center"
            style="
              background-color: #006a4e;

              color: aliceblue;
            "
          >
            <b class="text-center" origin="center center"
              >YTD Sales Report
            </b></v-card-title
          >
          <v-data-table
            :headers="salesheader"
            :items="salesitem"
            sort-by="name"
            hide-default-footer
            hide-default-header
            class="elevation-1"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th v-for="(h, i) in headers" :class="h.class" :key="i">
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col xl="6" md="6">
        <v-card>
          <v-card-title
            class="justify-center"
            style="
              background-color: #006a4e;

              color: aliceblue;
            "
            >Finanical Matrix
          </v-card-title>
          <v-data-table
            :headers="headersfinanical"
            :items="itemsfinanical"
            sort-by="name"
            hide-default-footer
            hide-default-header
            class="elevation-1"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th v-for="(h, i) in headers" :class="h.class" :key="i">
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>
  </div>
</template>
<script>
// import AreaDetails1 from './area-details.vue';
// import areadetails from "./areadetails.vue";
import moment from "moment";
export default {
  name: "customer-profile",

  components: {
    // areadetails
  },

  data() {
    return {
      customers: [],
      products: [],
      ProductId: "",
      CustomerId: "",
      salesitem: [],
      itemsYTD: [],
      headersfinanical: [
        {
          text: "Over Due",

          value: "overDue",
          class: "my-header-style",
        },
        { text: "LC", value: "customerLc", class: "my-header-style" },
        {
          text: "Balance",
          value: "customerBalance",
          class: "my-header-style",
        },
      ],
      itemsfinanical: [],
      salesheader: [
        {
          text: "ForeCast",

          value: "forecast",
          class: "my-header-style",
        },
        { text: "Actual Avg", value: "actualavg", class: "my-header-style" },
        { text: "YTD sales", value: "ytdsales", class: "my-header-style" },
        { text: "Contr. %", value: "countr%", class: "my-header-style" },
      ],

      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),

      EndDateRules: [
        () =>
          this.endDate >= this.startDate ||
          "تاريخ النهاية لا يمكن أن يسبق  تاريخ البداية",
      ],
      year: "",
      month: "",
      day: "",
      valid: true,
      modal: false,
      modal2: false,
      Rules: [(v) => !!v || "الحقل مطلوب"],
      date: "",
      date2: "",
      data: [],
      AnnualCustomer: [],
      options: {
        chart: {
          height: 350,
          type: "line",
        },

        xaxis: {
          type: "datetime",
          categories: [],

          labels: {},
        },
        title: {
          text: "Forecast",
          align: "left",
          style: {
            fontSize: "16px",
            color: "#666",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#FDD835"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
      },
    };
  },

  computed: {
    series() {
      let series = [
        {
          name: "Sales",
          data: this.data,
        },
      ];
      return series;
    },
  },
  watch: {
    startDate() {
      this.getYamamaSales();
    },
    endDate() {
      this.getYamamaSales();
    },
  },

  created() {
    // this.getByProductandCustomer()
    this.getYamamaSales();
    this.getDate();
    this.getCustomer();
    this.getProduct();
  },

  methods: {
    //http://yc.developmentdemos.com/RestFullApi/rest/getreportService/getActualSalesForEachCustomerFromAllAllProductInDay?fromDate=7/7/2022
    async getCustomer() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllCustomers"
      );
      response.data.responseData.forEach((el) => {
        if (
          el.customerNumber == 36 ||
          el.customerNumber == 40 ||
          el.customerNumber == 95 ||
          el.customerNumber == 153 ||
          el.customerNumber == 3663 ||
          el.customerNumber == 6081 ||
          el.customerNumber == 6106 ||
          el.customerNumber == 6206 ||
          el.customerNumber == 9498 ||
          el.customerNumber == 6331 ||
          el.customerNumber == 9496 ||
          el.customerNumber == 6454 ||
          el.customerNumber == 6105 ||
          el.customerNumber == 91 ||
          el.customerNumber == 6443
        )
          this.customers.push(el);
      });
    },
    async getProduct() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllProducts"
      );

      this.products = response.data.responseData;
  
      this.products.push({ productname: "total", productNumber: "total" });
    },
    async getByProductandCustomer() {
      if (this.$refs.form.validate()) {
        let startDate = moment(this.startDate).format("MM/DD/YYYY");
        let endDate = moment(this.endDate).format("MM/DD/YYYY");

        let url = `https://salestools.yamamacement.com/api/getreportService/getByCustomerAndProduct?fromDate=${startDate}&toDate=${endDate}&customerNo=${this.CustomerId}&productNo=${this.ProductId}`;

        const response = await this.ApiService.get(url);

        // JSON responses are automatically parsed.

        response.data.responseData.forEach((el) => {
          this.data.push(el.sumQuantity);

          this.options.xaxis.categories.push(el.deliveryDate);
        });
        // this.getYamamaSales()
        this.getYamamaDue();
        // JSON responses are automatically parsed.
      }
    },
    getDate() {
      var date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.day = date.getDate();
    },
    async getYamamaDue() {
      let startDate = moment(this.startDate).format("MM/DD/YYYY");

      let url = `https://salestools.yamamacement.com/api/getreportService/getCustomerOverDue?fromDate=${startDate}&&customerNo=${this.CustomerId}`;

      const response = await this.ApiService.get(url);
      // let urlOverDue = `https://salestools.yamamacement.com/api/getreportService/getOverDueForEachCustomer?fromDate=${startDate}&customerNo=${this.CustomerId}`;

      // const responseOverDue = await this.ApiService.get(urlOverDue);
      // JSON responses are automatically parsed.
    
      let item = [];
      response.data.responseData.forEach((el) => {
        item.push(el);
      });
      // item[0].overDue = responseOverDue.data.responseData[0].overDue;
    

      this.itemsfinanical = item;
    },
    async getYamamaSales() {
      this.salesitem = [];
      let startDate = moment(this.startDate).format("MM/DD/YYYY")
      let endDate = moment(this.endDate).format("MM/DD/YYYY")
      let dataYamama = [];

      let url = `https://salestools.yamamacement.com/api/getreportService/getDailyYamamaSales?fromDate=${startDate}&toDate=${endDate}`;

      const response = await this.ApiService.get(url);
      let ytdsales = 0;
      let actualavg = 0;

      response.data.forEach((el) => {
        dataYamama.push(el.quantity);
      });
      dataYamama.forEach((el) => {
        ytdsales = ytdsales + el;
      });

      actualavg = ytdsales / dataYamama.length;

      this.salesitem.push({
        actualavg: actualavg,
        ytdsales: ytdsales,
        countr: 0,
      });
    },
  },
};
</script>

<style scoped>
.row.tables {
  margin-top: 59px;
}
.flex.xs12 {
  padding: 14px !important;
}
</style>
